import getCollection, {allCollections, getCollectionDates} from "../Database/Database";
import {Collection} from "./Collection";
import {Helmet} from "react-helmet";


export default function CollectionIndex() {
    const url = window.location.href;
    const path = url.split("/").pop();

    const collectionItem = getCollection(path as string);

    if (!collectionItem) {
        const description = "כאן תוכלו למצוא את לקטי התקשורת שלנו";


        return <div className="CollectionPage">
            <Helmet>
                <title>לקטי תקשורת | שלו יחסי ציבור ואסטרטגיה</title>
                <meta name="description" content={description}/>
            </Helmet>

            <header className="section Header">
                <h1>לקטי תקשורת</h1>
            </header>

            <div className="collections">
                {allCollections().map(({collection, id}, index) => <div key={index} onClick={() => window.location.href = "collection/" + id}>
                        <div>
                            <span>{collection.title}</span>
                        </div>

                        <div>
                            <span>{getCollectionDates(collection)}</span>
                            <button>לחצו כאן</button>
                        </div>

                        <img src={collection.client.logo} alt={collection.client.name} className="logo-index"/>
                    </div>
                )}
            </div>
        </div>
    }


    return <Collection collectionItem={collectionItem}/>
}
