import {Item} from "./Types";
import React from 'react';


export type ArticleModalProps = {
    children: Item;
    setModal: (modal: boolean) => void;
}


export default function ArticleModalComponent({children, setModal}: ArticleModalProps) {
    const article = children;
    const modal = article.modal;
    
    if (!modal) 
        return null;
    
    const close = (event: any) => {
        if (event.target.classList.contains("ArticleModalWrapper")) {
            setModal(false);
        }
    }
    
    const open = () => {
        setModal(false);
        window.open(article.url, '_blank');
    }


    return <div className="ArticleModalWrapper" onClick={close}>
        <div className="ArticleModal">
            <span className="close" onClick={() => setModal(false)}>x</span>
            <button onClick={open}>פתיחת הכתבה</button>
            
            <header>
                <h1>{article.title}</h1>
                {/*<p>{article.author}</p>*/}
                {/*<p>{article.publishDate}</p>*/}
                <p>{article.author} - {article.publishDate}</p>
            </header>
        
            <main>
                {modal.map((item, index) => {
                    if (typeof item === "string") {
                        return <p key={index}>{item}</p>
                    } else {
                        switch (item.type) {
                            case "subtitle":
                                return <h2 key={index}>{item.data}</h2>
                            case "image":
                                return <img key={index} src={item.data} alt={article.title}/>
                            default:
                                return null;
                        }
                    }
                })}
            </main>
        </div>
    </div>
}
